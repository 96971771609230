<template>
    <VPage title="จัดการพนักงาน">
        <VModal
            v-model="visibleCreateForm"
            title="สร้างพนักงาน">
            <EmployeeCreateForm
                :stadium-id="stadiumId"
                :admin-mode="adminMode"
                @success="onCreateEntityPagination" />
        </VModal>
        <VModal
            v-model="visibleUpdateForm"
            title="แก้ไขข้อมูลพนักงาน">
            <EmployeeUpdateForm
                :id="selectedEntityId"
                :admin-mode="adminMode"
                @success="closeUpdateForm" />
        </VModal>
        <div class="employee__top-bar">
            <a-input-search
                placeholder="ค้นหา พนักงาน โดย ชื่อ เบอร์โทร หรือ อีเมล"
                style="width: 400px;margin-right:18px;"
                @change="handleQueryChange" />
            <a-button
                type="primary"
                @click="onOpenCreateForm">
                สร้าง
            </a-button>
        </div>
        <VTable
            :data-source="dataSource"
            :pagination="pagination"
            :columns="columns"
            :loading="loading"
            :custom-row="createClickToEditRow"
            @change="handleTableChange">
            <template
                v-slot:role="{ text }">
                {{ text|getStadiumRolePrettyString }}
            </template>
            <template
                v-slot:operation="{ record }">
                <a-popconfirm
                    v-if="dataSource.length"
                    title="ยืนยันการลบ?"
                    @confirm="onConfirmDelete(record)">
                    <a href="javascript:;" @click.stop>ลบ</a>
                </a-popconfirm>
            </template>
        </VTable>
    </VPage>
</template>
<script>
import { rootActions } from '@/store/types'
import EmployeeCreateForm from '@/components/EmployeeCreateForm.vue'
import EmployeeUpdateForm from '@/components/EmployeeUpdateForm.vue'
import VTable from '@/components/VTable.vue'
import VModal from '@/components/VModal.vue'
import VPage from '@/components/VPage.vue'
import VPermission from '@/mixins/VPermission'
import { StadiumRole, getEnumByValue } from '@/utils/enum'
import { includeText } from '@/utils/utils'
import EntitiesPagination from '@/mixins/EntitiesPagination'

export default {
    components: {
        EmployeeCreateForm,
        EmployeeUpdateForm,
        VModal,
        VTable,
        VPage,
    },
    mixins: [EntitiesPagination, VPermission],
    props: {
        stadiumId: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            size: 10,
            StadiumRole,
            columns: [
                {
                    title: 'ชื่อจริง',
                    dataIndex: 'firstName',
                    scopedSlots: { customRender: 'text' },
                },
                {
                    title: 'นามสกุล',
                    dataIndex: 'lastName',
                    scopedSlots: { customRender: 'text' },
                },
                {
                    title: 'ชื่อเล่น',
                    dataIndex: 'nickName',
                    width: 100,
                    scopedSlots: { customRender: 'text' },
                },
                {
                    title: 'เบอร์โทร',
                    dataIndex: 'tel',
                    // width: '10%',
                    scopedSlots: { customRender: 'text' },
                },
                {
                    title: 'อีเมล',
                    dataIndex: 'email',
                    scopedSlots: { customRender: 'text' },
                },
                {
                    title: 'ตำแหน่ง',
                    dataIndex: 'stadiumUserRoles[0].role',
                    width: 100,
                    scopedSlots: { customRender: 'role' },
                },
                // {
                //     title: 'Note',
                //     dataIndex: 'note',
                //     scopedSlots: { customRender: 'text' },
                // },
                {
                    title: 'การจัดการ',
                    dataIndex: 'operation',
                    width: 100,
                    fixed: 'right',
                    scopedSlots: { customRender: 'operation' },
                },
            ],
        }
    },
    computed: {
        me () {
            return this.$store.state.auth.me
        },
    },
    methods: {
        async fetchEntityPagination () {
            const { page, size, stadiumId } = this
            const res = await this.$store.dispatch(
                rootActions.LOAD_EMPLOYEES,
                { page, size, stadiumId },
            )
            return res
        },
        getEnumByValue (enumParent, value) {
            return getEnumByValue(enumParent, value)
        },
        getAllEntities () {
            return this.$store.state.employees.employees
        },
        // getAllEntities () {
        //     return this.$store.state.employees.employees.filter(
        //         (employee) => employee.stadiumUserRoles.findIndex(
        //             (stadiumUserRole) => stadiumUserRole.stadiumId === this.stadiumId,
        //         ) !== -1,
        //     )
        // },
        async delete (id) {
            await this.$store.dispatch(rootActions.DELETE_EMPLOYEE, id)
        },
        search (query) {
            if (!query) return [...this.entities]
            return this.entities.filter(
                (item) => includeText(item.firstName, query)
                            || includeText(item.lastName, query)
                            || includeText(item.nickName, query)
                            || includeText(item.tel, query)
                            || includeText(item.email, query),
            )
        },
        async onOpenCreateForm () {
            await this.$store.dispatch(rootActions.GET_ME)
            const { stadiumId, adminMode } = this
            if (this.isMeHasPermission(this.me, StadiumRole.EMPLOYEE, { stadiumId, adminMode })) {
                this.openCreateForm()
            } else {
                this.$store.dispatch(rootActions.ALERT_DONT_HAVE_PERMISSION)
            }
        },
        onConfirmDelete (record) {
            const { stadiumId, adminMode } = this
            if (this.isMeHasPermission(this.me, record.stadiumUserRole.role, { stadiumId, adminMode })) {
                this.onDeleteEntityPagination(record.id)
            } else {
                this.$store.dispatch(rootActions.ALERT_DONT_HAVE_PERMISSION)
            }
        },
    },
}
</script>
<style lang="stylus">
.employees__table
    clear both
.employee__top-bar
    margin-bottom: 24px
</style>
