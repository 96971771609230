<template>
    <div>
        <UserCreateAndUpdateFormItems
            :is-create-form="isCreateForm"
            :form="form"
            :data="data"
            :enable-change-password="true" />
        <a-form-item
            label="ตำแหน่ง">
            <a-radio-group
                v-decorator="
                    [ 'role',
                      {
                          initialValue: 1 ,
                          rules:
                              [
                                  { required: true,
                                    message: 'กรุณาเลือก ตำแหน่ง ด้วยค่ะ', }
                              ]
                      }
                    ]"
                :disabled="data ? !isMeHasPermission(
                    me,data.stadiumUserRoles[0].role,{ adminMode,stadiumId }
                ) : false">
                <a-radio
                    :disabled="!isMeHasPermission(me,StadiumRole.EMPLOYEE,{ adminMode,stadiumId })"
                    :value="StadiumRole.EMPLOYEE">
                    พนักงาน
                </a-radio>
                <a-radio
                    :disabled="!isMeHasPermission(me,StadiumRole.MANAGER,{ adminMode,stadiumId })"
                    :value="StadiumRole.MANAGER">
                    ผู้จัดการ
                </a-radio>
                <a-radio
                    :disabled="!isMeHasPermission(me,StadiumRole.OWNER,{ adminMode,stadiumId })"
                    :value="StadiumRole.OWNER">
                    เจ้าของ
                </a-radio>
            </a-radio-group>
        </a-form-item>
    </div>
</template>
<script>
import FormItems from '@/mixins/FormItems'
import { StadiumRole } from '@/utils/enum'
import VPermission from '@/mixins/VPermission'
import UserCreateAndUpdateFormItems from '@/components/UserCreateAndUpdateFormItems.vue'

export default {
    components: {
        UserCreateAndUpdateFormItems,
    },
    mixins: [FormItems, VPermission],
    props: {
        isCreateForm: {
            required: true,
            type: Boolean,
        },
        stadiumId: {
            type: String,
            default: null,
        },
    },
    data () {
        return {
            StadiumRole,
        }
    },
    computed: {
        me () {
            return this.$store.state.auth.me
        },
    },
    methods: {
        mapDataToFormItems (data) {
            return {
                role: data.stadiumUserRoles[0].role,
                requireChangePasswd: data.requireChangePasswd,
                username: data.username,
            }
        },
    },
}
</script>
