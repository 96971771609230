var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-item",
        { attrs: { label: "ชื่อสเตเดียม" } },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: [
                  "name",
                  {
                    rules: [{ required: true, message: "กรุณาใส่ชื่อด้วยค่ะ" }]
                  }
                ],
                expression:
                  "\n                [ 'name',\n                  { rules:\n                      [\n                          { required: true,\n                            message: 'กรุณาใส่ชื่อด้วยค่ะ', } ] } ]"
              }
            ]
          })
        ],
        1
      ),
      _c("VFormItemLogo", {
        attrs: { form: _vm.form, data: _vm.data, label: "โลโก้ (Logo)" }
      }),
      _c("VFormItemCoverPhoto", { attrs: { form: _vm.form, data: _vm.data } }),
      _c(
        "a-form-item",
        { attrs: { label: "คำบรรยายเบื้องต้น" } },
        [
          _c("a-textarea", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["description"],
                expression: "[ 'description' ]"
              }
            ],
            attrs: {
              placeholder:
                "เช่น เปิดมาแล้วกี่ปี มีสนามกีฬาอะไรบ้าง เคยใช้เป็นที่จัดแข่งอะไรมาบ้าง",
              "auto-size": { minRows: 2, maxRows: 6 }
            }
          })
        ],
        1
      ),
      _c("VFormItemPhones", { attrs: { form: _vm.form, data: _vm.data } }),
      _c(
        "a-form-item",
        { attrs: { label: "สิ่งอำนวยความสะดวก" } },
        [
          _c("a-select", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["facilities"],
                expression: "\n                [ 'facilities' ]"
              }
            ],
            staticStyle: { width: "100%" },
            attrs: {
              mode: "tags",
              "token-separators": [","],
              "dropdown-class-name": "v--hidden",
              placeholder: "เช่น ห้องอาบน้ำ, ห้องน้ำ, ผ้าเช็ดหน้า"
            }
          })
        ],
        1
      ),
      _c("VFormItemOpenAndCloseTime", {
        attrs: { form: _vm.form, data: _vm.data }
      }),
      _c(
        "a-form-item",
        { attrs: { label: "ที่อยู่" } },
        [
          _c("a-textarea", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["address"],
                expression: "[ 'address' ]"
              }
            ],
            attrs: {
              placeholder: "บ้านเลขที่ ถนน อำเภอ จังหวัด",
              "auto-size": { minRows: 2, maxRows: 6 }
            }
          })
        ],
        1
      ),
      _c("VFormItemLocation", { attrs: { form: _vm.form, data: _vm.data } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }