var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VForm", {
    attrs: { data: _vm.employee, "on-submit-async": _vm.submit },
    on: {
      success: function(value) {
        return this$1.$emit("success", value)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var form = ref.form
          var data = ref.data
          return [
            !_vm.isMeHasPermission(_vm.me, data.stadiumUserRoles[0].role, {
              stadiumId: _vm.stadiumId,
              adminMode: _vm.adminMode
            })
              ? _c("a-alert", {
                  staticClass: "employee-update-form_alert",
                  attrs: {
                    message:
                      "สิทธิของคุณไม่เพียงพอที่จะทำได้\n         กรุณาติดต่อคนที่มี ตำแหน่ง สูงกว่านี้ให้เป็นคนทำแทนค่ะ",
                    banner: "",
                    closable: ""
                  }
                })
              : _vm._e(),
            _c("EmployeeCreateAndUpdateFormItems", {
              attrs: {
                "admin-mode": _vm.adminMode,
                "stadium-id": _vm.stadiumId,
                form: form,
                data: data,
                "is-create-form": false
              }
            }),
            _c("PeopleCreateAndUpdateFormItems", {
              attrs: { form: form, data: data }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }