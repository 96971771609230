<template>
    <VForm
        v-slot="{ form,data }"
        :data="employee"
        :on-submit-async="submit"
        @success="(value)=>this.$emit('success',value)">
        <a-alert
            v-if="!isMeHasPermission(me,data.stadiumUserRoles[0].role,{ stadiumId,adminMode })"
            class="employee-update-form_alert"
            message="สิทธิของคุณไม่เพียงพอที่จะทำได้
             กรุณาติดต่อคนที่มี ตำแหน่ง สูงกว่านี้ให้เป็นคนทำแทนค่ะ"
            banner
            closable />
        <EmployeeCreateAndUpdateFormItems
            :admin-mode="adminMode"
            :stadium-id="stadiumId"
            :form="form"
            :data="data"
            :is-create-form="false" />
        <PeopleCreateAndUpdateFormItems :form="form" :data="data" />
    </VForm>
</template>
<script>
import EmployeeCreateAndUpdateFormItems from '@/components/EmployeeCreateAndUpdateFormItems.vue'
import PeopleCreateAndUpdateFormItems from '@/components/PeopleCreateAndUpdateFormItems.vue'
import VForm from '@/components/VForm.vue'
import VPermission from '@/mixins/VPermission'
import { Getters } from '@/store/modules/employees/types'
import { rootActions } from '@/store/types'
import { StadiumRole } from '@/utils/enum'

export default {
    components: {
        EmployeeCreateAndUpdateFormItems,
        PeopleCreateAndUpdateFormItems,
        VForm,
    },
    mixins: [VPermission],
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            StadiumRole,
        }
    },
    computed: {
        employee () {
            return this.$store.getters[Getters.GET_EMPLOYEE](this.id)
        },
        me () {
            return this.$store.state.auth.me
        },
        stadiumId () {
            return this.employee.stadiumUserRoles[0].stadiumId
        },
    },
    methods: {
        async submit (values) {
            const { role } = values
            const { adminMode, stadiumId } = this
            if (this.isMeHasPermission(this.me, this.employee.stadiumUserRoles[0].role, { adminMode, stadiumId })) {
                const res = await this.$store.dispatch(
                    rootActions.UPDATE_EMPLOYEE, {
                        ...values,
                        id: this.id,
                        stadiumUserRoles: [{
                            ...this.employee.stadiumUserRoles[0],
                            role,
                        }],
                    },
                )
                return res.isSuccess()
            }
            this.$store.dispatch(rootActions.ALERT_DONT_HAVE_PERMISSION)
            throw Error('ALERT_DONT_HAVE_PERMISSION')
        },
    },
}
</script>
<style lang="stylus">
.employee-update-form_alert
    margin-bottom 16px
</style>
