var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("VFormItemRangeTime", {
        attrs: {
          "label-start-time": "เวลาเปิด",
          "label-end-time": "เวลาปิด",
          "name-prop-start-time": "openTime",
          "name-prop-end-time": "closeTime",
          form: _vm.form,
          data: _vm.data
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }