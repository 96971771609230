var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form-item",
    { attrs: { label: "โทรศัพท์" } },
    [
      _c("a-select", {
        directives: [
          {
            name: "decorator",
            rawName: "v-decorator",
            value: [
              "phones",
              {
                rules: [
                  {
                    required: true,
                    message: "กรุณาใส่เบอร์โทรศัพท์อย่างน้อย 1 เบอร์ด้วยค่ะ"
                  }
                ]
              }
            ],
            expression:
              "\n            [ 'phones' ,\n              { rules: [ { required: true,\n                           message: 'กรุณาใส่เบอร์โทรศัพท์อย่างน้อย 1 เบอร์ด้วยค่ะ' } ] } ]"
          }
        ],
        staticStyle: { width: "100%" },
        attrs: {
          mode: "tags",
          "token-separators": [","],
          "dropdown-class-name": "v--hidden",
          placeholder: "เข่น 066-666-6666, 088-888-8888"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }