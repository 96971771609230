var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VPage",
    { attrs: { title: "จัดการพนักงาน" } },
    [
      _c(
        "VModal",
        {
          attrs: { title: "สร้างพนักงาน" },
          model: {
            value: _vm.visibleCreateForm,
            callback: function($$v) {
              _vm.visibleCreateForm = $$v
            },
            expression: "visibleCreateForm"
          }
        },
        [
          _c("EmployeeCreateForm", {
            attrs: { "stadium-id": _vm.stadiumId, "admin-mode": _vm.adminMode },
            on: { success: _vm.onCreateEntityPagination }
          })
        ],
        1
      ),
      _c(
        "VModal",
        {
          attrs: { title: "แก้ไขข้อมูลพนักงาน" },
          model: {
            value: _vm.visibleUpdateForm,
            callback: function($$v) {
              _vm.visibleUpdateForm = $$v
            },
            expression: "visibleUpdateForm"
          }
        },
        [
          _c("EmployeeUpdateForm", {
            attrs: { id: _vm.selectedEntityId, "admin-mode": _vm.adminMode },
            on: { success: _vm.closeUpdateForm }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "employee__top-bar" },
        [
          _c("a-input-search", {
            staticStyle: { width: "400px", "margin-right": "18px" },
            attrs: {
              placeholder: "ค้นหา พนักงาน โดย ชื่อ เบอร์โทร หรือ อีเมล"
            },
            on: { change: _vm.handleQueryChange }
          }),
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.onOpenCreateForm } },
            [_vm._v("\n            สร้าง\n        ")]
          )
        ],
        1
      ),
      _c("VTable", {
        attrs: {
          "data-source": _vm.dataSource,
          pagination: _vm.pagination,
          columns: _vm.columns,
          loading: _vm.loading,
          "custom-row": _vm.createClickToEditRow
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "role",
            fn: function(ref) {
              var text = ref.text
              return [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm._f("getStadiumRolePrettyString")(text)) +
                    "\n        "
                )
              ]
            }
          },
          {
            key: "operation",
            fn: function(ref) {
              var record = ref.record
              return [
                _vm.dataSource.length
                  ? _c(
                      "a-popconfirm",
                      {
                        attrs: { title: "ยืนยันการลบ?" },
                        on: {
                          confirm: function($event) {
                            return _vm.onConfirmDelete(record)
                          }
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:;" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            }
                          },
                          [_vm._v("ลบ")]
                        )
                      ]
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }