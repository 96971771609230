<template>
    <a-form-item
        class="v-form-item-location"
        label="ตำแหน่ง"
        has-feedback>
        <a-button @click="moveToMyLocation">
            เลื่อนมาตำแหน่งปัจจุบัน
        </a-button>
        <a-select
            v-decorator="[ 'location',
                           { rules: [
                               { required: true,
                                 message: 'กรุณา คลิกซ้ายในแผนที่\
                                 เพื่อตั้งที่อยู่ของ สเตเดียม บนแผนที่ด้วยค่ะ' } ] } ]"
            class="v--hidden" />
        <div class="v-form-item-location__map v-form-item-location__map--loading">
            <a-spin
                :spinning="loading"
                tip="กำลังโหลด . . ."
                size="large">
                <gmap-map
                    :center="center"
                    :zoom="16.75"
                    class="v-form-item-location__map"
                    @click="pinMarker">
                    <gmap-marker
                        v-if="marker"
                        :position="marker.position"
                        :clickable="true"
                        @click="center = marker.position" />
                </gmap-map>
            </a-spin>
        </div>
    </a-form-item>
</template>
<script>
import FormItems from '@/mixins/FormItems'
import { isEqual } from '@/utils/utils'

export default {
    mixins: [FormItems],
    data () {
        return {
            marker: {
                // position: {
                //     lat: 13.74694,
                //     lng: 100.5348449,
                // },
            },
            center: {
                lat: 13.74694,
                lng: 100.5348449,
            },
            loading: false,
        }
    },
    beforeMount () {
        this.form.getFieldDecorator('location', {
            initialValue: null,
            preserve: true,
        })
    },
    mounted () {
        if (this.isEmptyData()) {
            this.moveToMyLocation()
        }
    },
    methods: {
        pinMarker (e) {
            this.marker = {
                position: {
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng(),
                },
            }
            this.form.setFieldsValue({
                location: [this.marker.position.lng, this.marker.position.lat],
            })
        },
        mapDataToFormItems (info) {
            if (info && info.location) {
                this.marker = {
                    position: {
                        lat: info.location[1],
                        lng: info.location[0],
                    },
                }
                this.center = this.marker.position
                this.form.setFieldsValue({
                    location: [info.location[0], info.location[1]],
                })
            }
            return {}
        },
        moveToMyLocation () {
            this.loading = true
            navigator.geolocation.getCurrentPosition((position) => {
                const newCenter = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                }
                if (isEqual(this.center, newCenter)) {
                    /** *
                     * Sometime center on map has been change but this.center is not update yet
                     * so we need to force update center
                     * even if newCenter is the same as this.center
                     * by adding super mini value
                     */
                    this.center = {
                        lat: newCenter.lat + 0.0000001,
                        lng: newCenter.lng + 0.0000001,
                    }
                } else {
                    this.center = {
                        lat: newCenter.lat,
                        lng: newCenter.lng,
                    }
                }
                this.loading = false
            })
        },
    },
}

</script>
<style lang="stylus">
$map-height=480px

.v-form-item-location__map
    width 100%;
    height $map-height;
.v-form-item-location__map--loading
    text-align: center;
    line-height: $map-height;
    border-radius: 4px;
.v-form-item-location
    .has-feedback
        .ant-form-item-children-icon
            width auto
            right -5px

</style>
