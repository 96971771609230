var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("UserCreateAndUpdateFormItems", {
        attrs: {
          "is-create-form": _vm.isCreateForm,
          form: _vm.form,
          data: _vm.data,
          "enable-change-password": true
        }
      }),
      _c(
        "a-form-item",
        { attrs: { label: "ตำแหน่ง" } },
        [
          _c(
            "a-radio-group",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: [
                    "role",
                    {
                      initialValue: 1,
                      rules: [
                        {
                          required: true,
                          message: "กรุณาเลือก ตำแหน่ง ด้วยค่ะ"
                        }
                      ]
                    }
                  ],
                  expression:
                    "\n                [ 'role',\n                  {\n                      initialValue: 1 ,\n                      rules:\n                          [\n                              { required: true,\n                                message: 'กรุณาเลือก ตำแหน่ง ด้วยค่ะ', }\n                          ]\n                  }\n                ]"
                }
              ],
              attrs: {
                disabled: _vm.data
                  ? !_vm.isMeHasPermission(
                      _vm.me,
                      _vm.data.stadiumUserRoles[0].role,
                      { adminMode: _vm.adminMode, stadiumId: _vm.stadiumId }
                    )
                  : false
              }
            },
            [
              _c(
                "a-radio",
                {
                  attrs: {
                    disabled: !_vm.isMeHasPermission(
                      _vm.me,
                      _vm.StadiumRole.EMPLOYEE,
                      { adminMode: _vm.adminMode, stadiumId: _vm.stadiumId }
                    ),
                    value: _vm.StadiumRole.EMPLOYEE
                  }
                },
                [_vm._v("\n                พนักงาน\n            ")]
              ),
              _c(
                "a-radio",
                {
                  attrs: {
                    disabled: !_vm.isMeHasPermission(
                      _vm.me,
                      _vm.StadiumRole.MANAGER,
                      { adminMode: _vm.adminMode, stadiumId: _vm.stadiumId }
                    ),
                    value: _vm.StadiumRole.MANAGER
                  }
                },
                [_vm._v("\n                ผู้จัดการ\n            ")]
              ),
              _c(
                "a-radio",
                {
                  attrs: {
                    disabled: !_vm.isMeHasPermission(
                      _vm.me,
                      _vm.StadiumRole.OWNER,
                      { adminMode: _vm.adminMode, stadiumId: _vm.stadiumId }
                    ),
                    value: _vm.StadiumRole.OWNER
                  }
                },
                [_vm._v("\n                เจ้าของ\n            ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }