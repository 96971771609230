<template>
    <div>
        <VFormItemRangeTime
            label-start-time="เวลาเปิด"
            label-end-time="เวลาปิด"
            name-prop-start-time="openTime"
            name-prop-end-time="closeTime"
            :form="form"
            :data="data" />
    </div>
</template>

<script>
import VFormItemRangeTime from '@/components/VFormItemRangeTime.vue'

export default {
    components: {
        VFormItemRangeTime,
    },
    props: {
        form: {
            required: true,
            type: Object,
        },
        data: {
            type: Object,
            default: null,
        },
    },
}
</script>
