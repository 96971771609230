<template>
    <a-form-item
        label="โทรศัพท์">
        <a-select
            v-decorator="
                [ 'phones' ,
                  { rules: [ { required: true,
                               message: 'กรุณาใส่เบอร์โทรศัพท์อย่างน้อย 1 เบอร์ด้วยค่ะ' } ] } ]"
            mode="tags"
            style="width: 100%"
            :token-separators="[ ',' ]"
            dropdown-class-name="v--hidden"
            placeholder="เข่น 066-666-6666, 088-888-8888" />
    </a-form-item>
</template>
<script>
import FormItems from '@/mixins/FormItems'

export default {
    mixins: [FormItems],
    methods: {
        mapDataToFormItems (data) {
            return {
                phones: data.phones === null ? undefined : data.phones,
            }
        },
    },
}
</script>
