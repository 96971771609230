<template>
    <VForm
        v-slot="{ form }"
        :on-submit-async="submit"
        @success="(value)=>this.$emit('success',value)">

        <EmployeeCreateAndUpdateFormItems
            :form="form"
            :is-create-form="true"
            :stadium-id="stadiumId"
            :admin-mode="adminMode" />
        <PeopleCreateAndUpdateFormItems :form="form" />
    </VForm>
</template>
<script>
import PeopleCreateAndUpdateFormItems from '@/components/PeopleCreateAndUpdateFormItems.vue'
import EmployeeCreateAndUpdateFormItems from '@/components/EmployeeCreateAndUpdateFormItems.vue'
import VForm from '@/components/VForm.vue'
import { rootActions } from '@/store/types'
import VPermission from '@/mixins/VPermission'

export default {
    components: {
        PeopleCreateAndUpdateFormItems,
        EmployeeCreateAndUpdateFormItems,
        VForm,
    },
    mixins: [VPermission],
    props: {
        stadiumId: {
            type: String,
            required: true,
        },
    },
    computed: {
        name () {
            return this.data
        },
    },
    methods: {
        async submit (values) {
            const { role } = values
            const res = await this.$store.dispatch(
                rootActions.ADD_EMPLOYEE, {
                    ...values,
                    id: this.id,
                    stadiumUserRoles: [{
                        stadiumId: this.stadiumId,
                        role,
                    }],
                },
            )
            return res
        },
    },
}
</script>
