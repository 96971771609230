<template>
    <div>
        <a-form-item
            label="ชื่อสเตเดียม">
            <a-input
                v-decorator="
                    [ 'name',
                      { rules:
                          [
                              { required: true,
                                message: 'กรุณาใส่ชื่อด้วยค่ะ', } ] } ]" />
        </a-form-item>
        <VFormItemLogo
            :form="form"
            :data="data"
            label="โลโก้ (Logo)" />
        <VFormItemCoverPhoto :form="form" :data="data" />
        <a-form-item
            label="คำบรรยายเบื้องต้น">
            <a-textarea
                v-decorator="[ 'description' ]"
                placeholder="เช่น เปิดมาแล้วกี่ปี มีสนามกีฬาอะไรบ้าง เคยใช้เป็นที่จัดแข่งอะไรมาบ้าง"
                :auto-size="{ minRows: 2, maxRows: 6 }" />
        </a-form-item>
        <VFormItemPhones :form="form" :data="data" />
        <a-form-item
            label="สิ่งอำนวยความสะดวก">
            <a-select
                v-decorator="
                    [ 'facilities' ]"
                mode="tags"
                style="width: 100%"
                :token-separators="[ ',' ]"
                dropdown-class-name="v--hidden"
                placeholder="เช่น ห้องอาบน้ำ, ห้องน้ำ, ผ้าเช็ดหน้า" />
        </a-form-item>
        <VFormItemOpenAndCloseTime :form="form" :data="data" />
        <a-form-item
            label="ที่อยู่">
            <a-textarea
                v-decorator="[ 'address' ]"
                placeholder="บ้านเลขที่ ถนน อำเภอ จังหวัด"
                :auto-size="{ minRows: 2, maxRows: 6 }" />
        </a-form-item>
        <VFormItemLocation :form="form" :data="data" />
    </div>
</template>

<script>
import VFormItemOpenAndCloseTime from '@/components/VFormItemOpenAndCloseTime.vue'
import VFormItemCoverPhoto from '@/components/VFormItemCoverPhoto.vue'
import VFormItemLogo from '@/components/VFormItemLogo.vue'
import FormItems from '@/mixins/FormItems'
import VFormItemPhones from '@/components/VFormItemPhones.vue'
import VFormItemLocation from '@/components/VFormItemLocation.vue'

export default {
    components: {
        VFormItemOpenAndCloseTime,
        VFormItemCoverPhoto,
        VFormItemPhones,
        VFormItemLogo,
        VFormItemLocation,
    },
    mixins: [FormItems],
    methods: {
        mapDataToFormItems (info) {
            this.phones = info.phones || []
            return {
                name: info.name,
                description: info.description,
                facilities: info.facilities,
                openTime: info.openTime,
                closeTime: info.closeTime,
                address: info.address,
            }
        },
    },
}
</script>
